import { FC, ReactElement, lazy, Suspense, useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';
import { setTopBar, TopBarInfo } from '@/state/base/actions';
import '../../locale/main';
const TopBar = lazy(() => import('@/components/TopBar'));
const TransferIn = lazy(() => import('@/page/TransferIn'));
const TransferOut = lazy(() => import('@/page/TransferOut'));
const TransferDetail = lazy(() => import('@/page/TransferDetail'));
const TransferRecord = lazy(() => import('@/page/TransferRecord'));

const TopBarRoute: FC = (): ReactElement => {
    const { pathname, search } = useLocation();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        setBar();
        // setTimeout(() => {
        //     console.error('start login')
        //     dispatch(getUserInfo())
        // },2000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, search]);

    const setBar = (): void => {
        let query = qs.parse(search),
            info: TopBarInfo;
        switch (pathname) {
            case '/transfer/in':
                info = { title: t('transferIn.main'), goback: '' };
                break;
            case '/transfer/out':
                info = { title: t('transferOut.main'), goback: '' };
                break;
            case '/transfer/detail':
                info = { title: query.type === 'in' ? t('transferDetail.main in') : t('transferDetail.main out'), goback: '' };
                break;
            case '/transfer/record':
                info = { title: t('transferRecord.main'), goback: '/' };
                break;
            default:
                info = { title: t('transferIn.main'), goback: '' };
        }
        dispatch(setTopBar(info));
    };

    return (
        <Suspense fallback={<div></div>}>
            <TopBar>
                <Switch>
                    <Route path="/transfer/in" component={TransferIn} />
                    <Route path="/transfer/out" component={TransferOut} />
                    <Route path="/transfer/detail" component={TransferDetail} />
                    <Route path="/transfer/record" component={TransferRecord} />
                    <Route path="/transfer" render={() => <Redirect to="/transfer/in" />} />
                </Switch>
            </TopBar>
        </Suspense>
    );
};
export default TopBarRoute;
