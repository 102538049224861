import { message } from 'antd';
import {EOSErrorCode} from "utils/eosErrorCode";
export {

}

(Date as any).prototype.format = function(fmt:any) {
    let o:any = {
        "M+" : this.getMonth()+1,                 //月份
        "d+" : this.getDate(),                    //日
        "h+" : this.getHours(),                   //小时
        "m+" : this.getMinutes(),                 //分
        "s+" : this.getSeconds(),                 //秒
        "q+" : Math.floor((this.getMonth()+3)/3), //季度
        "S"  : this.getMilliseconds()             //毫秒
    };
    if(/(y+)/.test(fmt)) {
        fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length));
    }
    for(let k in o) {
        if(new RegExp("("+ k +")").test(fmt)){
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length===1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
        }
    }
    return fmt;
};


(Array as any).prototype.insert = function(index:number, data:any) {
    this.splice(index, 0, data);
};

// (Array as any).prototype.splice = function(start:number, lang:number, data:any) {
//     this.splice(start, lang, data);
// };

(Array as any).prototype.delete = function(index:number, data:any) {
    this.splice(index, 1);
};


//Copy Text
(Window as any).prototype.copy = (text:any,successTitle?:string): Promise<void> => {
    try{
        var textArea:any = document.createElement('textarea')
        textArea.style.position = 'fixed'
        textArea.style.top = 0
        textArea.style.left = 0
        textArea.style.width = '2em'
        textArea.style.height = '2em'
        textArea.style.padding = 0
        textArea.style.border = 'none'
        textArea.style.outline = 'none'
        textArea.style.boxShadow = 'none'
        textArea.style.background = 'transparent'
        textArea.value = text
        document.body.appendChild(textArea)
        textArea.select()
        try {
            document.execCommand('copy')
        } catch (err) {
            console.log('Oops, unable to copy')
        }
        document.body.removeChild(textArea);
        message.success(successTitle ?? '复制成功')
        return Promise.resolve()
    }catch (e) {
        console.error('copy error', e)
        message.success('复制失败')
        return Promise.reject()
    }
}

//Open Browser
(Window as any).prototype.openUrl = (url:string): Promise<void> => {
    if ((navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i))) {
        try{
            let msg = {
                type: 'EVENT_OPEN_BROWSER',
                value: url
            };
            (Window as any).ReactNativeWebView.postMessage(JSON.stringify(msg));
        }catch (e) {
            (Window as any).open(url)
        }
    }else{
        (Window as any).open(url)
    }
    return Promise.resolve()
}

(Window as any).prototype.toastError = (e:any,language:string): void => {
    try{
        const _errorResult = JSON.parse(e);

        if (_errorResult.error.details[0].message.indexOf('[[') !== -1) {
            const eosMsg = EOSErrorCode.getEOSErrorMessage(_errorResult.error.details[0].message.split('[[')[1].split(']]')[0], language)
            message.error(eosMsg);
        }else{
            const eosMsg = EOSErrorCode.getEOSErrorMessage(String(_errorResult.error.code), language)
            message.error(eosMsg)
        }

    }catch(e){
        message.error('ERROR');
    }
}








