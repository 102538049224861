import {Route, BrowserRouter, Switch} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import React, {lazy, Suspense, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from './state';
import {getCanchUserInfo} from '@/state/base/reducer';

import {drak, light} from './theme';

import {setLanguage} from "state/base/actions";
import {useTranslation} from "react-i18next";
import TopBarRoute from "@/routeLayout/TopBarRoute";

const Home = lazy(() => import('@/page/Home'));
const StyledThemeProvider = props => {
    const dispatch = useDispatch();
    const baseInfo = useSelector((state: AppState) => state.baseInfo);
    useEffect(() => {
        setTimeout(() => {
            dispatch(getCanchUserInfo())
        }, 1000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return <ThemeProvider theme={baseInfo.themeMode === 'light' ? light : drak} {...props} />;
};

const Entry: React.ComponentType = () => {
    const {i18n} = useTranslation();
    const dispatch = useDispatch();
    useEffect(() => {
        let lange = 'zh_CN'
        let LangData = null
        if (window.navigator.userAgent.indexOf('{') > -1) {
            LangData = JSON.parse('{' + window.navigator.userAgent.split('{')[1]).Lang
            console.log('LangDataLangData', LangData)
        }
        if (LangData) {
            lange = LangData;
        } else {
            lange = window.localStorage.getItem('lange') ?? 'en_US';
        }
        i18n.changeLanguage(lange);
        dispatch(setLanguage(lange));
        window.localStorage.setItem('lange', lange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n]);
    return (
        <BrowserRouter>
            <Suspense fallback={<div></div>}>
                <StyledThemeProvider>
                    <Switch>
                        <Route path="/transfer" component={TopBarRoute} />
                            <Route path="/" component={Home} exact={true}/>
                            <Route component={Home}/>
                    </Switch>
                </StyledThemeProvider>
            </Suspense>
        </BrowserRouter>
    );
};
export default Entry;
