import { createAction } from '@reduxjs/toolkit';

export enum UserStatus {
    efficient = 1,
    unefficient = 2,
}
export type Theme = 'drak' | 'light';
export type Language = string;
export type AccountStatus = 0 | 1; // 0: 未激活   1：已激活

export interface AcountState {
    kyc: boolean;
    name: string;
    publicKey: string;
    status: AccountStatus;
}
export interface TopBarInfo {
    title: string;
    goback?: string;
}

export interface CoinType  {
    title: string,
    key: string,
}

export const setUserInfo = createAction<AcountState>('base/setUserInfo');
export const setTheme = createAction<Theme>('base/setTheme');
export const setLanguage = createAction<Language>('base/setLanguage');
export const setTopBar = createAction<TopBarInfo>('base/setTopBar');
export const setCoinList = createAction<CoinType[]>('base/setCoinList');
export const setCoinListOut = createAction<CoinType[]>('base/setCoinListOut');
export const setCoinInfo = createAction<Record<string, any>>('base/setCoinInfo');
export const setCoinInfoOut = createAction<Record<string, any>>('base/setCoinInfoOut');
export const setChainInfo = createAction<Record<string, any>>('base/setChainInfo');
export const setGlobalCoinLange = createAction<Language>('base/setGlobalCoinLange');
