import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

let lange = 'zh_CN'
let LangData = null
if (window.navigator.userAgent.indexOf('{') > -1) {
    LangData = JSON.parse('{' + window.navigator.userAgent.split('{')[1]).Lang
}

if (LangData) {
    lange = LangData;
} else {
    lange = window.localStorage.getItem('lange') ?? 'en_US';
}

i18n
    // .use(LanguageDetector)  //嗅探当前浏览器语言
    .use(initReactI18next) //init i18next
    .init({
        //引入资源文件
        resources: {
            en_US: {
                translation: require('./en.json'),
            },
            zh_CN: {
                translation: require('./zh_CN.json'),
            },
            ko_KR: {
                translation: require('./ko_KR.json'),
            },
            ru: {
                translation: require('./ru.json'),
            },
            zh_HK: {
                translation: require('./zh_HK.json'),
            },
        },

        //选择默认语言，选择内容为上述配置中的key，即en/zh
        fallbackLng: lange,
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    }).catch(() => {
})


export default i18n;
