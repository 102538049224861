import Amax from '@amax/amaxjs';

import AnchorLink, {
    APIError,
    ChainId, IdentityProof,
    LinkChannelSession,
    LinkSession,
} from '@amax/anchor-link';
import AnchorLinkBrowserTransport from '@amax/anchor-link-browser-transport';
import i18n from "i18next";
import {Storage} from "utils/storage";
const { REACT_APP_NETWORK_CHAINID, REACT_APP_NETWORK_HOST, REACT_APP_NETWORK_PORT, REACT_APP_NETWORK_PROTOCOL } = process.env;
export const broadcast = false;
console.log('chainId---', REACT_APP_NETWORK_CHAINID)

export const network = {
    blockchain: 'amax',
    expireInSeconds: 600,
    // host: '913.224.250.244', // ( or null if endorsed chainId )
    host: REACT_APP_NETWORK_HOST, // ( or null if endorsed chainId )
    port: REACT_APP_NETWORK_PORT, // ( or null if defaulting to 80 )
    chainId: REACT_APP_NETWORK_CHAINID, // Or null to fetch automatically ( takes longer )
    protocol: REACT_APP_NETWORK_PROTOCOL,
};

const options = {
    broadcast,
    sign: true,
    expireInSeconds: 300,
    retry: {
        count: 6,
        // delay: 1000,
    },
};
export const blockchains = [
    {
        chainId: network.chainId,
        name: network.blockchain,
        rpcEndpoints: [
            {
                protocol: network.protocol,
                host: network.host,
                port: 0,
            },
        ],
    },
];
export const scope = 'xChain';

export const isAPLink = window.navigator.userAgent
    .toLowerCase()
    .includes('aplink');

export async function getAccount() {
    const scatter = getScatter();
    const { accounts } = await scatter.getIdentity({
        accounts: [{ chainId: network.chainId, blockchain: network.blockchain }],
    });
    return accounts[0];
}
export async function getClient() {
    const scatter = getScatter();
    if (scatter) {
        if (!window.scatterAMAX) {
            let account = await getAccount();
            const walletAddress = Storage.getItem('walletAddress');
            if (account.name !== walletAddress) {
                scatter.forgetIdentity();
            }
            window.scatterAMAX = scatter.amax(
                network,
                Amax,
                {
                    ...options,
                    broadcast:
                        options.broadcast ||
                        !(window.tinyBrige && window.tinyBrige.pushTransaction),
                    authorization: [`${account.name}@${account.authority}`],
                },
                network.protocol,
            );
        }

        return window.scatterAMAX;
    }
}

/**
 * 获取帐户下的pubkey
 * @param {*} account
 * @param {*} authority owner | active 只获取此权限下的pubkey
 * @returns
 */
export const getAccountPubkey = async (account, authority) => {
    if (!account) return;
    const client = await getClient();
    let pubkeyArray;
    try {
        const accountInfo = await client.getAccount(account);
        if (accountInfo) {
            pubkeyArray = accountInfo.permissions
                .filter(item => authority ? item.perm_name === authority : true)
                .reduce((pre, next) => {
                    next.required_auth.keys.forEach(
                        k => !pre.includes(k.key) && pre.push(k.key),
                    );
                    return pre;
                }, []);
        }
    } catch (e) {
        throw new Error(e);
    }
    return pubkeyArray;
};
export function getScatter() {
    return window.scatter;
}

export function getLocalClient() {
    const client = Amax({
        httpEndpoint: `${network.protocol}://${network.host}`,
        chainId: network.chainId,
    });
    return client;
}

export function initLink() {
    if (!window.__LINK__) {
        let localeVal = 'en-us'
        if(i18n.language === 'zh-TW' || i18n.language === 'zh'){
            localeVal = 'zh-cn'
        }
        const transport = new AnchorLinkBrowserTransport({
            currentLocale: localeVal,
        });
        const link = new AnchorLink({
            transport,
            service: 'https://fwd.aplink.app', // 'ws://192.168.80.152:7001', // 'http://fwd.aplink.app', //
            chains: [
                {
                    chainId: network.chainId,
                    nodeUrl: `${network.protocol}://${network.host}`,
                },
            ],
        });

        link.restoreSession(scope).then(session => {
            console.log('session', session);
        });

        window.__LINK__ = link;
    }
    onAppRemoveSession();
    return window.__LINK__;
}
export async function onAppRemoveSession() {
    console.log('sessionRemove');
    if (!window.__LINK__) {
        return;
    }
    const session: LinkSession = await window.__LINK__.restoreSession(scope);
    console.log('sessionRemovesss', session);
    if (session) {
        (session as LinkChannelSession).onAppRemoveSession(() => {
            Storage.removeItem('walletAddress');
            Storage.removeItem('authority');
            Storage.removeItem('chainId');
            (window as any).__LINK__.clearSessions(scope);
            window.location.reload();
        });
    }
}
export async function verifyProof(link, identity) {
    // Generate an array of valid chain IDs from the demo configuration
    const chains = blockchains.map(chain => chain.chainId);

    // Create a proof helper based on the identity results from anchor-link
    const proof = IdentityProof.from(identity.proof);

    // Check to see if the chainId from the proof is valid for this demo
    const chain = chains.find(id => ChainId.from(id).equals(proof.chainId.toString()));
    if (!chain) {
        throw new Error('Unsupported chain supplied in identity proof');
    }

    // Load the account data from a blockchain API
    // let account: API.v1.AccountObject;
    let account = null;
    try {
        account = await link.client.v1.chain.get_account(proof.signer.actor);
    } catch (error) {
        if (error instanceof APIError && error.code === 0) {
            throw new Error('No such account');
        } else {
            throw error;
        }
    }

    // Retrieve the auth from the permission specified in the proof
    const auth = account.getPermission(proof.signer.permission).required_auth;

    // Determine if the auth is valid with the given proof
    const valid = proof.verify(auth, account.head_block_time);

    // If not valid, throw error
    if (!valid) {
        throw new Error('Proof invalid or expired');
    }

    // Recover the key from this proof
    const proofKey = proof.recover();

    // Return the values expected by this demo application
    return {
        account,
        proof,
        proofKey,
        proofValid: valid,
    };
}
// merchant=merchant1
// Private key: 5JZTokHvSvRXbskUqzhU5paafQbqUEj13qyQ9vDpW6NHK67gUbb
// Public key: AM5cizvBZobd1rNhw5N89wuqevzZbLxk2x6QZQVWvFdg3uTFpEJV

// merchant=merchantx
// Private key: 5JfxfDzeSTXiSbTzhxCo1eAmxbHd88THgS11ZZ6hLZqSPMCH636
// Public key: AM8HxjZAiqEY5mHCPXjCb7xEaU2tkmqdAek2TXX1CxTbnYMBqadX

// merchant=merchantx2
// Private key: 5Kk2pP4S1Q6MvMc6vit5vc8bSNmzyddRptcWCRngA2Mff4fynMP
// Public key: AM79jCfBdjEMP5fHmWMv4W1G6zMVGespL9nBvG4ETA5dJKQsRbgq

// conf=oxo.conf
// Private key: 5JGBUZNqqsJLNUANj2c7wyDZ7Ct84s92ojdRTcL8fu6uJxJcPrH
// Public key: AM6ttUE76EZw8E4MJVtHGaMfY8pcsSRf2EBqZhrHpyXZfhvho3ce

// book=oxo.cash
// Private key: 5J6MbPZ51wqu5jNJXb2Lv1X9mxVwn5UsaWEd4vs41kmcttVRHVG
// Public key: AM64xpLyp5CWPRs9YS1ce9rjBMXUS4azX5BtFh6wJAhvpZDT4Q2D
